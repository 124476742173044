import React, { useState, useEffect, useRef } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import "../../styles/reset.scss"
import "../../styles/global.scss"
import "../../styles/layout.scss"

import useBodyScrollLock from "../../hooks/useBodyScrollLock"
import Gamebar from "./gameBar"
import Hamburger from "../svg/hamburger"

const Layout = ({ location, title, cssPageName, children }) => {

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      logo: file(name: {eq: "USWL_logo"}) {
        childImageSharp {
          fluid(maxWidth:500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ...january
      ...february
      ...march
      ...april
      ...may
      ...june
      ...july
      ...august
      ...september
      ...october
      ...november
      ...december
      }
  `)

  const [todaysGames, setTodaysGames] = useState([])
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const { logo, ...calendar } = data

  const allGames = Object.values(calendar).map(({ edges }) => edges).flat(1).map(({ node }) => node.childScheduleJson).map(month => Object.values(month)).flat(2).filter(day => day !== null)

  const handleMenuToggle = () => {
    setMenuIsOpen(!menuIsOpen)
  }

  useEffect(() => {
    const today = new Date()
    const day = today.getDate() > 9 ? today.getDate() : "0" + today.getDate()
    const month = today.getMonth() > 9 ? today.getMonth() + 1 : "0" + (today.getMonth() + 1)
    const year = today.getFullYear()
    const todayISODateString = year + "-" + month + "-" + day

    setTodaysGames(allGames.filter(game => game.datetime.substr(0, 10) === todayISODateString))
    // eslint-disable-next-line
  }, [])

  let header = (
    <>
      {menuIsOpen && <MobileMenu handleMenuToggle={handleMenuToggle} />}
      <Link className="logo-container" to="/">
        {!menuIsOpen && <Image className="logo" fluid={logo.childImageSharp.fluid} alt="United States Winter League logo." />}
      </Link>
      <div className="viewbars">
        {/* Gets display: none; from SCSS when small viewport as a Safari fix */}
        <nav className="navbar large">
          <div className="nav-row">
            <Link className="nav-link" to={`/news`}>News</Link>
            <Link className="nav-link" to={`/schedule`}>Schedule</Link>
            <Link className="nav-link" to={`/stats-and-standings`}>Stats and Standings</Link>
          </div>
          <div className="nav-row">
            <Link className="nav-link" to={`/teams`}>Teams</Link>
            {/* <Link className="nav-link" to={`/tickets`}>Tickets</Link> */}
            {/* <Link className="nav-link" to={`/facilities`}>Facilities</Link> */}
            <Link className="nav-link" to={`/join-the-uswl`}>Join the USWL</Link>
            <Link className="nav-link" to={`/contact-us`}>Contact Us</Link>
          </div>
        </nav>
        {/* Gets display: none; from SCSS when large viewport as a Safari fix */}
        <nav className="navbar small"><button className="mobile-menu-open" onClick={handleMenuToggle}><Hamburger />Menu</button></nav>

        <Gamebar location={location} todaysGames={todaysGames} />
      </div>
    </>
  )

  let footer = (
    <>
      <Link className="logo-container" to="/">
        <Image className="logo" fluid={logo.childImageSharp.fluid} alt="United States Winter League logo." />
      </Link>
      <div className="viewbars">
        <nav className="navbar">
          <div className="nav-row">
            <Link className="nav-link" to={`/league-office`}>League Office</Link>
            {/* <Link className="nav-link" to={`/development-league`}>Development League</Link> */}
            <Link className="nav-link" to={`/contact-us`}>Contact Us</Link>
          </div>
          <div className="nav-row">
            <Link className="nav-link" to={`/legal/terms-of-use`}>Terms of Use</Link>
            <Link className="nav-link" to={`/legal/privacy-policy`}>Privacy Policy</Link>
          </div>
        </nav>
        <Gamebar todaysGames={todaysGames} />
      </div >
    </>
  )


  return (
    <>
      <header className="header">{header}</header>
      <main className={`main${cssPageName ? ` page-${cssPageName}` : ""}`}>{children}</main>
      <footer className="footer">
        {footer}
      </footer>
    </>
  )
}

export default Layout

const MobileMenu = ({ handleMenuToggle }) => {
  useBodyScrollLock()
  const menuContainer = useRef()
  const menuBody = useRef()
  const closeButton = useRef()

  const closeMenu = () => {
    menuContainer.current.classList.add("out")
    menuBody.current.classList.add("out")
    closeButton.current.classList.add("out")
    setTimeout(handleMenuToggle, 300)
  }

  return (
    <nav className="mobile-menu">
      <img className="logo" src="/USWL_logo.png" alt="United States Winter League logo." />
      <button className="close-menu" onClick={closeMenu} ref={closeButton}>Close X</button>
      <div className="mobile-menu-container" ref={menuContainer}>
        <div className="mobile-menu-body" ref={menuBody}>
          <Link className="nav-link" to={`/news`}>News</Link>
          <Link className="nav-link" to={`/schedule`}>Schedule</Link>
          <Link className="nav-link" to={`/stats-and-standings`}>Stats and Standings</Link>
          <Link className="nav-link" to={`/teams`}>Teams</Link>
          <Link className="nav-link" to={`/join-the-uswl`}>Join the USWL</Link>
          {/* <Link className="nav-link" to={`/development-league`}>Development League</Link> */}
          <Link className="nav-link" to={`/league-office`}>League Office</Link>
          <Link className="nav-link" to={`/contact-us`}>Contact Us</Link>
        </div>
      </div>
    </nav>
  )
}