import React from 'react'

export default ({ ...props }) => {
    return (
        <svg viewBox="0 0 73 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="72" height="11" rx="5.5" fill="#112344" />
            <rect x="1" y="19" width="72" height="11" rx="5.5" fill="#112344" />
            <rect x="1" y="37" width="72" height="11" rx="5.5" fill="#112344" />
        </svg>

    )
}