import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import usePreviousValue from "../../hooks/usePreviousValue"

export default ({ location, todaysGames }) => {
    const [isScrolling, setIsScrolling] = useState(false)
    const [clientX, setClientX] = useState(0)
    const prevClientX = usePreviousValue(clientX)
    const gameBar = useRef(null)

    const handleMouseDown = (e) => {
        setIsScrolling(true)
        setClientX(e.clientX)
    }

    const handleMouseUp = (e) => {
        setIsScrolling(false)
        setClientX(0)
    }

    const handleMouseMove = (e) => {
        if (isScrolling) {
            if (prevClientX > clientX) {
                gameBar.current.scrollLeft = gameBar.current.scrollLeft + 8
            } else {
                gameBar.current.scrollLeft = gameBar.current.scrollLeft - 8
            }
            setClientX(e.clientX)
        }
    }

    return (
        <>
            {/*eslint-disable-next-line*/}
            <nav className={`gamebar${todaysGames.length > 0 ? "" : " no-game"}`} ref={gameBar} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
                {todaysGames.length > 0 ? todaysGames.map((game, index) => (
                    <GameBox
                        key={index}
                        awayScore={game.awayScore}
                        awayTeam={game.awayTeam}
                        datetime={game.datetime}
                        homeScore={game.homeScore}
                        homeTeam={game.homeTeam}
                        inning={game.inning}
                        inningStatus={game.inningStatus}
                        status={game.status}
                        watchLink={game.watchLink}
                    />
                )) : <>
                        <p className="no-game-p">No&nbsp;games&nbsp;scheduled&nbsp;today!</p>
                        <Link className="no-game-link" to="/schedule"> Visit&nbsp;the&nbsp;full&nbsp;schedule.</Link>
                    </>
                }
            </nav>
        </>
    )
}





export const GameBox = ({
    awayScore,
    awayTeam,
    datetime,
    homeScore,
    homeTeam,
    inning,
    inningStatus,
    status,
    watchLink
}) => {
    const timeConverter = (isoDateTime) => {
        const datetime = new Date(isoDateTime)

        const twoDigitMaker = (num) => num > 9 ? num : "0" + num

        const hour = datetime.getUTCHours() > 12 ? datetime.getUTCHours() - 12 : datetime.getUTCHours()
        const minutes = twoDigitMaker(datetime.getUTCMinutes())
        const timeEnding = hour >= 12 ? "AM" : "PM"

        return `${hour}:${minutes} ${timeEnding}`

    }

    const statusText = status === "Postponed" ? `PP` :
        status === "Completed" ? `F` :
            status === "Delayed" ? `(Delay) ${inningStatus} ${inning} ` :
                status === "Scheduled" ? `${timeConverter(datetime)} ` :
                    `${inningStatus} ${inning} `



    return (
        <div className="game-box">
            <div className="status">
                {statusText}
            </div>
            <div className="scores">
                <div className="away">
                    <span>{awayTeam}</span><span>{status === "Postponed" || status === "Scheduled" ? "" : awayScore}</span>
                </div>
                <div className="home">
                    <span>{homeTeam}</span><span>{status === "Postponed" || status === "Scheduled" ? "" : homeScore}</span>
                </div>
            </div>
            {watchLink ? <a className="watch-link" href={watchLink} target="_blank" rel="noopener noreferrer">Watch ></a> : <a className="watch-link disabled" href="/" disabled={true}>Watch Unavailable</a>}
        </div>
    )
}